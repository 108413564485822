import React from "react";
import "./App.css";
import underConstruction from "./assets/underConstruction.png";
function App() {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 200 }}>
      <img src={underConstruction} alt="construction" style={{ width: 500 }} />
    </div>
  );
}

export default App;
